.bottom-nav {
    width: 92%;
    background: rgba(254, 254, 254, 0.70);
    border-radius: 10px;
    border: .5px solid white;
    height: 60px;
    box-shadow: 7px 7px 11px -7px rgba(0,0,0,0.46);
    -webkit-box-shadow: 7px 7px 11px -7px rgba(0,0,0,0.46);
    -moz-box-shadow: 7px 7px 11px -7px rgba(0,0,0,0.46);
    /* WebkitBoxShadow: 7px 7px 11px -7px rgba(0,0,0,0.46);
    MozBoxShadow: 7px 7px 11px -7px rgba(0,0,0,0.46); */
}

.mozilla .bottom-nav {
    width: 92%;
    background: red;
    border-radius: 10px;
    border: .5px solid white;
    height: 60px;
    box-shadow: 7px 7px 11px -7px rgba(0,0,0,0.46);
    -webkit-box-shadow: 7px 7px 11px -7px rgba(0,0,0,0.46);
    -moz-box-shadow: 7px 7px 11px -7px rgba(0,0,0,0.46);
    /* WebkitBoxShadow: 7px 7px 11px -7px rgba(0,0,0,0.46);
    MozBoxShadow: 7px 7px 11px -7px rgba(0,0,0,0.46); */
}