/*--------------------------------------------------------------
	3. content
--------------------------------------------------------------*/

/*--------------------------------------------------------------
	3.1 wpo-hero-slider
--------------------------------------------------------------*/
.wpo-hero-slider,
.static-hero,
.wpo-hero-slider-s2,
.wpo-hero-slider-s3 {
	width: 100%;
	height: 1000px;
	display: flex;
	position: relative;
	z-index: 0;
	@include media-query(991px) {
		height: 600px;
	}
	@include media-query(767px) {
		height: 500px;
	}
	.swiper-slide {
		overflow: hidden;
	}
	.swiper-container,
	.hero-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}
	.slide-inner,
	.hero-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #313131;
			content: "";
			opacity: .40;
			z-index: -1;
		}
		.slide-content {
			padding: 60px 70px;
			max-width: 1020px;
			margin: 0 auto;
			text-align: center;
			position: relative;
			@include media-query(1199px) {
				max-width: 800px;
			}
			@include media-query(991px) {
				max-width: 530px;
			}
			@include media-query(767px) {
				max-width: 385px;
				padding: 80px 30px;
			}
			@include media-query(575px) {
				padding: 30px 20px;
			}
		}
	}
	.slick-prev,
	.slick-next {
		background-color: transparentize($white, 0.6);
		width:60px;
		height: 60px;
		z-index: 10;
		@include rounded-border(50%);
		transition: all .3s;
		border: 2px solid $white;
	}
	.slick-prev {
		left: 20px;
		@include media-query(767px) {
			display: none !important;
		}
		&:before {
			font-family: "themify";
			content: "\e629";
			opacity: 1;
		}
	}
	.slick-next {
		right: 20px;
		@include media-query(767px) {
			display: none !important;
		}
		&:before {
			font-family: "themify";
			content: "\e628";
			opacity: 1;
		}
	}
}

.wpo-hero-slider,
.static-hero,
.wpo-hero-slider-s2 {
	@include media-query(1199px) {
		height: 680px;
	}
	@include media-query(991px) {
		height: 600px;
	}
	@include media-query(767px) {
		height: 500px;
	}
	.gradient-overlay {
		@include linear-gradient-bg(to left, $dark-gray 45%, transparent 65%);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		opacity: .6;
	}
	.gradient-overlay + .container {
		position: relative;
		z-index: 11;
	}
	.swiper-slide {
		position: relative;
		z-index: 11;
	}
	.wpo-hero-title-top {
		span {
			font-size: 22px;
			color: #e4e4e4;
			font-family: $heading-font;
			@include media-query(767px) {
				font-size: 15px;
			}
		}
	}
	.slide-title {
		h2 {
			font-size: 100px;
			font-weight: 500;
			line-height: 60px;
			margin:10px 0 35px;
			color: $white;
			@include media-query(1199px) {
				font-size: 50px;
				font-size: calc-rem-value(50);
			}
			@include media-query(991px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
				line-height: 55px;
				margin-bottom: 20px;
			}
			@include media-query(767px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
				line-height: 36px;
			}
		}
	}
	.slide-text {
		p {
			font-size: 30px;
			color: $white;
			line-height: 45px;
			max-width: 780px;
			color: $white;
			margin: 0 auto;
			margin-bottom: 30px;
			@include media-query(991px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
				line-height: 30px;
				margin-bottom: 25px;
			}
			@include media-query(767px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
				line-height: 22px;
				margin-bottom: 30px;
			}
		}		
	}
	.slide-btns {
		@include media-query(991px) {
			max-width: 400px;
		}
		.theme-btn {
			border-radius: 0;
			font-weight: 600;
			font-size: 18px;
			&:after {
				border-radius: 0;
			}
		}
	}
	.slide-btns .hero-video-btn {
		margin-left: 40px;
		@include media-query(767px) {
			margin-left: 20px;
		}
	}
}

/*-------------------------------------------
	wpo-hero-slider-s2
--------------------------------------------*/
.wpo-hero-slider-s2 {
	height: 900px;
	@include media-query(1199px) {
		height: 680px;
	}
	@include media-query(991px) {
		height: 600px;
	}
	@include media-query(767px) {
		height: 500px;
	}
	.hero-inner,
	.slide-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #00315f;
			content: "";
			opacity: .25;
			z-index: -1;
		}
		.slide-content {
		   	max-width: 760px;
		   	position: relative;
		   	background: none;
		   	padding: 80px 70px;
		   	@media(max-width:991px) {
				max-width: 650px;
		  	}
		   	@media(max-width:767px) {
				max-width: 450px;
				padding: 40px 50px;
			}
		   	h2 {
			   text-transform: capitalize;
			   @media(max-width:1199px) {
                    margin-bottom: 10px;
			   }
			}
			p {
				margin-bottom: 0;
			}
		}
	}
}

/*-------------------------------------------
	hero style 1
--------------------------------------------*/
.static-hero-left {
	padding-top: 45vh;
	border: 2px solid white;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 10px;
	margin: 20px;
	position: relative;
	top: 0px;
	z-index: 10;
	text-align: -webkit-center;
	.static-hero-shape-1 {
		position: absolute;
		top: -250px;
		left: -250px;
		z-index: -3;
		transform: rotate(-90deg);
		img {
			width: 500px;
			height: 500px;
			border-radius: 0;
			opacity: 100%;
		}
	}

	.static-hero-shape-2 {
		position: absolute;
		bottom: auto;
		right: 0px;
		left: 0px;
		z-index: -1;
		transform: rotate(0deg);
		img {
			padding-top: 15%;
			width: 10rem;
			border-radius: 0;
			opacity: 50%;
			@media (max-width: 991px) {}
			@media (max-width: 650px) {}
			@media (max-width: 400px) {
				padding-top: 20%;
				width: 8rem;
			}
		}
	}
	.static-hero-shape-3 {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		z-index: -3;
		img {
			border-radius: 0;
			opacity: 100%;
			border-radius: 10px;
			height: 100%;
			object-fit: cover;
			object-position: 75% 25%;
			// object-position: center bottom;
			// width: 100%;
			@media (max-width: 991px) {}
			@media (max-width: 650px) {}
			@media (max-width: 400px) {}
		}
	}
}
.static-hero-right {
	position: relative;
	top: 0px;
	z-index: 10;
	text-align: -webkit-center;
	padding-top: 20%;
	padding-bottom: 20%;
	img {
		border-radius: 300px;
	}
	@include media-query(1600px) {}
	@include media-query(1400px) {}
	@include media-query(1199px) {}
	@include media-query(991px) {}
	@media (max-width: 767px) {}
	@media (max-width: 650px) {}
	.static-hero-img {
		z-index: 1;
		padding: 30px;
		box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.06);
		background: $white;
		border-radius: 320px;
		z-index: 1;
		max-width: 60%;
		@media (max-width: 650px) {
			max-width: 350px;
			margin:0 auto;
		}
		@media (max-width: 400px) {
			max-width: 300px;
			margin:0 auto;
		}
	}
	.static-hero-shape-1 {
		position: absolute;
		bottom: -20px;
		left: 0px;
		z-index: -1;
		img {
			border-radius: 0;
		}
	}
	.static-hero-shape-2 {
		position: absolute;
		right: 0px;
		top: 0px;
		z-index: -1;
		img{
			border-radius: 0;
		}
	}
	@media (max-width: 991px) {}
	@media (max-width: 650px) {}
	@media (max-width: 400px) {}
}
.static-hero {
	background: $white;
}
.slide-title{
	text-align: center;
	h2 {
		// color: $theme-primary-color;
		color: white;
		@media(max-width:1850px) {
			font-size: 2.7rem;
			span {
				font-size: 3.7rem;
				font-weight: bold;
			}
		}
		@media(max-width:1500px) {
			font-size: 2.7rem;
			span {
				font-size: 3.7rem;
				font-weight: bold;
			}
		}
		@include media-query(1199px) {
			font-size: 2.2rem;
			span {
				font-size: 2.7rem;
				font-weight: bold;
			}
		}
		@include media-query(1100px) {
			font-size: 1.7rem;
			span {
				font-size: 2.7rem;
				font-weight: bold;
			}
		}
		@include media-query(530px) {
			font-size: 1.7rem;
			span {
				font-size: 2.2rem;
				font-weight: bold;
			}
		}
	}
}
.slide-text {
	p {
		font-size: 30px;
		line-height: 45px;
		max-width: 780px;
		margin: 0 auto;
		margin-top: 10px;
		margin-bottom: 0px;
		@include media-query(991px) {
			font-size: 1rem;
			font-size: calc-rem-value(18);
			line-height: 30px;
			margin-bottom: 0px;
		}
		@include media-query(767px) {
			font-size: 1rem;
			font-size: calc-rem-value(16);
			line-height: 22px;
			margin-bottom: 0px;
		}
	}		
}
.wpo-wedding-date {
	padding-bottom: 0;
	display: flex;
	justify-content: center;
	@include media-query(1199px) {
		margin-top: 20px;
	}
	@include media-query(991px) {
		display: flex;
		justify-content: center;
		margin-top: 0;
	}
	.countdown-wrapper {
		overflow: hidden;
		margin-top: 20px;
		@include media-query(530px) {
			margin-top: 0;
		}
		> .time-section {
			float: left;
			&:after {
				display: none;
			}
		}
		> .time-section + .time-section {
			margin-left: 10px;
			@include media-query(1800px) {}
			@include media-query(1500px) {}
			@include media-query(991px) {}
			@include media-query(767px) {}
		}
		.time {
			font-family: $heading-font;
			font-size: 80px;
			font-size: calc-rem-value(80);
			line-height: 1em;
			padding-top: 15px;
			color: lightgrey;
			font-weight: 500;
			@include media-query(1900px) {
				font-size: calc-rem-value(30);
			}
			@include media-query(1300px) {
				font-size: calc-rem-value(30);
			}
			@include media-query(1100px) {
				font-size: calc-rem-value(20);
			}
			@include media-query(991px) {
				font-size: calc-rem-value(30);
			}
			@include media-query(767px) {
				font-size: calc-rem-value(20);
			}
		}
		.time-text {
			color: lightgrey;
			font-size: calc-rem-value(15);
			display: block;
			text-align: center;
			@include media-query(1500px) {
				font-size: calc-rem-value(15);
			}
			@media(max-width:991px){
				font-size: calc-rem-value(15);
			}
		}
	}
}
@-webkit-keyframes spineer {
	from {
	  -webkit-box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
	  box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
	}
	to {
	  -webkit-box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
	  box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
	}
}
@keyframes spineer {
	from {
	  -webkit-box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
	  box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
	}
	to {
	  -webkit-box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
	  box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
	}
}

/*-------------------------------------------
	wpo-hero-style-2
--------------------------------------------*/
.wpo-hero-style-2,
.wpo-hero-style-4 {
	position: relative;
	height: 900px;
	border-radius: 30px;
	@include media-query(1399px) {
		height: 720px;
	}
	@include media-query(1199px) {
		height: 700px;
	}
	@include media-query(767px) {
		height: 600px;
	}
	@media(max-width:575px) {
		height: 500px;
	}
	.hero-inner,
	.slide-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #00315f;
			content: "";
			opacity: .25;
			z-index: -1;
		}
	}
	.hero-slide {
		height: 900px;
		@include media-query(1399px) {
			height: 720px;
		}
		@include media-query(1199px) {
			height: 700px;
		}
		@include media-query(767px) {
			height: 600px;
		}
		@media(max-width:575px) {
			height: 500px;
		}
	}
	.slide-inner,
	.swiper-container {
		border-radius: 30px;
	}
	.slide-inner:before {
		background: #2a2a2a;
		opacity: .45;
		border-radius: 30px;
	}
	.wedding-announcement {
		padding-bottom: 115px;
		@include widther(992px) {
			width: 80%;
			left: 10%;
		}
		@media(max-width:767px) {
			padding-bottom: 0;
		}
		width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    z-index: 2;
	    text-align: center;
	    display: table;
		.couple-text {
			width: 100%;
			display: table-cell;
			vertical-align: middle;
			@media(max-width:575px) {
				padding: 0 20px;
			}
			.shape-1 {
				margin-bottom: 40px;
				img {
					display: unset;
				}
				@media(max-width:575px) {
					img {
						max-width: 70%;
					}
					margin-bottom: 20px;
				}
			}
			.shape-2 {
				margin-top: 40px;
				img {
					display: unset;
				}
				@media(max-width:575px) {
					img {
						max-width: 70%;
					}
					margin-top: 20px;
				}
			}
			h2 {
				font-size: 100px;
				font-weight: 500;
				color: $white;
				@media(max-width:991px) {
					font-size: 80px;
				}
				@media(max-width:767px) {
					font-size: 50px;
				}
				@media(max-width:575px) {
					font-size: 35px;
				}
			}
			p {
				font-size: 30px;
				font-family: $heading-font;
				color: $white;
				font-weight: 500;
				@media(max-width:767px) {
					font-size: 18px;
				}
				@media(max-width:575px) {
					font-size: 15px;
				}
			}
		}
	}
}

/*-------------------------------------------
	wpo-hero-style-3
--------------------------------------------*/
.wpo-hero-style-3{
	@include media-query(991px) {
		height: 850px;
	}
	@include media-query(767px) {
		height: 750px;
	}
	@include media-query(575px) {
		height: 600px;
	}
	@include media-query(540px) {
		height: 650px;
	}
	.hero-inner,
	.slide-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #313131;
			content: "";
			opacity: 0.4;
			z-index: -1;
		}
	}
	.hero-slide {
		height: 1000px;
		@include media-query(991px) {
			height: 850px;
		}
		@include media-query(767px) {
			height: 750px;
		}
		@include media-query(575px) {
			height: 600px;
		}
		@include media-query(540px) {
			height: 650px;
		}
	}
	.slide-inner {
		.slide-content {
			padding-top: 130px;
			@include media-query(991px) {
				padding: 0;
				padding-top: 130px;
				text-align: center;
				max-width: 600px;
			}
			@include media-query(540px) {
				padding-top: 200px;
			}
		}
	}
	.slide-title-sub {
		margin-bottom: 40px;
		@include media-query(1199px) {
			margin-bottom: 20px;
		}
		span {
			font-size: 30px;
			color: $white;
			@include media-query(767px) {
				font-size: 20px;
			}
			img {
				display: unset;
				@include media-query(767px) {
					max-width: 17%;
				}	
			}
		}
	}
	.slide-title {
		h2 {
			font-size: 85px;
			line-height: 110px;
			@include media-query(1199px) {
				font-size: 50px;
				font-size: calc-rem-value(50);
				line-height: 60px;
			}
			@include media-query(991px) {
				font-size: 60px;
				font-size: calc-rem-value(60);
				line-height: 65px;
				margin-bottom: 20px;
			}
			@include media-query(767px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
				line-height: 36px;
			}
		}
	} 
	.slide-btns {
		@include media-query(991px) {
			max-width: 100%;
		}
		.theme-btn {
			background: none;
			border: 1px solid $white;
			border-radius: 40px;
			&:hover {
				background: $theme-primary-color;
			}
		}
	}

}

/*-------------------------------------------
	wpo-hero-style-4
--------------------------------------------*/
.wpo-hero-style-4 {
	border-radius: 0px;
	.slide-inner,
	.swiper-container {
		border-radius: 0px;
	}

	.slide-inner:before {
		border-radius: 0px;
	}
}

/*-------------------------------------------
	wpo-hero-slider-s3
--------------------------------------------*/
.wpo-hero-slider-s3{
	@media(max-width:767px) {
    	height: 600px;
	}
	.hero-inner,
	.slide-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #313131;
			content: "";
			opacity: 0.4;
			z-index: -1;
		}
	}
	.hero-slide {
		height: 1000px;
		@include media-query(991px) {
			height: 850px;
		}
		@include media-query(767px) {
			height: 600px;
		}
		@include media-query(575px) {
			height: 600px;
		}
		@include media-query(540px) {
			height: 650px;
		}
	}
	
	.slide-inner {
		.slide-content {
			position: relative;
			z-index: 1;
			text-align: left;
			margin: 0;
			padding-left: 130px;
			@media(max-width:991px) {
              padding-left: 20px;
			}
			@media(max-width:767px) {
              text-align: center;
			  margin: 0 auto;
			}
			h2 {
				color: $white;
				font-weight: 600;
				margin-bottom: 40px;
				@media(max-width:767px) {
					font-size: 32px;
					margin-bottom: 10px;
				}
				@media(max-width:575px) {
					font-size: 25px;
				}
			}
			p {
				max-width: 600px;
				margin: 0;
				margin-bottom: 30px;
			}
			.shape-1 {
				padding-bottom: 60px;
				@include media-query(1199px) {
					padding-bottom: 30px;
				}
				@include media-query(991px) {
					padding-bottom: 20px;
				}
			}
			.shape-2 {
				padding-top: 60px;
				@include media-query(1199px) {
					padding-top: 30px;
				}
			}
		}
	} 
}

/*-------------------------------------------
	static-hero-s3
--------------------------------------------*/
.static-hero-s3,
.static-hero-s4 {
	height: 843px;
	background-size: cover;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@include media-query(991px) {
		height: 700px;
	}
	@include media-query(550px) {
		height: 550px;
	}
	@include media-query(450px) {
		height: 550px;
	}
	.hero-inner {
		.row {
			justify-content: center;
		}
	}
	.wpo-event-item {
		padding: 20px;
		position: relative;
		margin-bottom: 40px;
		width: 530px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 0 auto;
		background-size: contain;
		box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.11);
		border-radius: 10px;
		background: #ffffffcc;
		@media(max-width:550px) {
			width: 420px;
		}
		@media(max-width:450px) {
			width: 310px;
		}
		.wpo-event-text {
			padding: 40px 35px;
			text-align: center;
			@media(max-width:1200px) {
				padding: 25px 15px;
			}
			h2 {
				font-size: 50px;
				font-weight: 500;
				@media(max-width:550px) {
					font-size: 30px;
				}
				@media(max-width:450px) {
					font-size: 25px;
				}
			}
			p {
				font-size: 20px;
				color: darken($theme-primary-color-s2, 9%);
				margin-bottom: 30px;
				@media(max-width:550px) {
					font-size: 12px;
					margin-bottom: 5px;
				}
			}
			ul {
				list-style: none;
				max-width: 260px;
				margin: auto;
				li {
					margin-bottom: 15px;
					font-size: 18px;
					line-height: 30px;
					margin-left: 0;
					float: none;
					color: #7b7b7b;
					@media(max-width:550px) {
						font-size: 14px;
						margin-bottom: 5px;
					}
					@media(max-width:450px) {
						margin-bottom: 2px;
					}
					&:last-child {
						margin-bottom: 0;
					}
					button {
						font-weight: 700;
						color: $theme-primary-color;
						position: relative;
						display: inline-block;
						margin-top: 20px;
						font-size: 16px;
						text-transform: capitalize;
						&:focus {
							outline: none;
							box-shadow: none;
						}
						&:hover {
							background: none;
						}
						@media(max-width:450px) {
							margin-top:10px;
						}
						&:before {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							height: 1px;
							content: "";
							background: $theme-primary-color;
						}
					}
				}
			}
		}
	}
}

/*-------------------------------------------
	Animate
--------------------------------------------*/
.heartbeat {
	-webkit-animation: heartbeat 3s ease-in-out infinite both;
	animation: heartbeat 3s ease-in-out infinite both;
}
@-webkit-keyframes heartbeat {
	from {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	10% {
		-webkit-transform: scale(0.91);
		transform: scale(0.91);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	17% {
		-webkit-transform: scale(0.98);
		transform: scale(0.98);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	33% {
		-webkit-transform: scale(0.87);
		transform: scale(0.87);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	45% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}
@keyframes heartbeat {
	from {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	10% {
		-webkit-transform: scale(0.91);
		transform: scale(0.91);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	17% {
		-webkit-transform: scale(0.98);
		transform: scale(0.98);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	33% {
		-webkit-transform: scale(0.87);
		transform: scale(0.87);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	45% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}