.dropbox {
    outline: 2px dashed grey;
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
}
.input-file {
    opacity: 0;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}
.div-judul-artikel {
    border-bottom: solid 1px lightgrey; 
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.p-judul-postingan-right {
    margin-bottom: 0px;
    text-align: right;
    font-size: .8rem;
    font-weight: 500;
}
.p-judul-postingan-left {
    margin-bottom: 0px;
    text-align: left;
    font-size: .8rem;
    font-weight: 500;
}
.text-area-custom { 
    width: 100%;
    font-size: .9rem;
    margin-bottom: 10px;
}